import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" required/>
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" required/>
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="6" required/>
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Send Message" className="primary" />
          </li>
          <li>
            <input type="reset" value="Clear" />
          </li>
        </ul>
      </form>
    </section>
    <section>
      <h2>Contact Me</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>Eastlake, Chula Vista, CA 91914 &bull; USA</dd>
        <dt>Phone</dt>
        <dd>+1-619-693-7148</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:mulicazra@gmail.com">mulicazra@gmail.com</a>
        </dd>
      </dl>
    </section>
    <p className="copyright">
      &copy; 2022 Azra Mulic
    </p>
  </footer>
)

export default Footer
